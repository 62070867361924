import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-cache-element"
export default class extends Controller {
  static targets = ['elementToCache', 'elementToReplace']

  connect () {
    this.initialHref = document.location.href
    this.boundReplaceCache = this.replaceCache.bind(this)
    document.addEventListener('turbo:before-cache', this.boundReplaceCache)
  }

  disconnect () {
    document.removeEventListener('turbo:before-cache', this.boundReplaceCache)
  }

  elementToCacheTargetConnected () {
    this.clonedElement = this.elementToCacheTarget.cloneNode(true)
    delete this.clonedElement.dataset.turboCacheElementTarget
  }

  replaceCache () {
    // we make sure that the URL when caching is the same as the initial URL,
    // otherwise, if the URL are different, it means that we stayed on the same
    // page but the parameters changed (for example when doing a search)
    if (document.location.href === this.initialHref) {
      this.elementToReplaceTarget.replaceWith(this.clonedElement)
    }
  }
}
