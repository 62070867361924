import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="delayed-reveal"
export default class extends Controller {
  static values = {
    timeout: Number
  }

  connect () {
    this.timeoutID = setTimeout(() => {
      this.element.dataset.revealed = 1
    }, this.timeoutValue)
  }

  disconnect () {
    clearTimeout(this.timeoutID)
  }
}
