import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller="select-items"
export default class extends Controller {
  static targets = ['createButton', 'inputField', 'newItemLabel']
  connect () {
    this.fullyOpened = false
    setTimeout(() => {
      enter(this.element)
      this.fullyOpened = true
    }, 0)
  }

  async hide (event) {
    if (!this.fullyOpened || (event && this.element.contains(event.target))) {
      return
    }
    await leave(this.element)
    this.element.remove()
    this.element.dispatchEvent(new Event('closed.omnis'))
  }

  selectItem (event) {
    this.dispatchEvent(event)

    this.hide()
  }

  searchItem (event) {
    const currentInput = event.currentTarget
    const value = currentInput.value.toLowerCase()
    const parent = currentInput.closest('[data-controller="select-items"]')
    const lis = parent.querySelectorAll('ul > li[data-label]')
    const result = Array.from(lis).filter((li) => {
      return (li.dataset.label.toLowerCase().includes(value))
    })

    if (result.length <= 0) {
      if (this.hasCreateButtonTarget) this.createButtonTarget.classList.remove('hidden')
      if (this.hasInputFieldTarget) this.inputFieldTarget.value = currentInput.value
      if (this.hasNewItemLabelTarget) this.newItemLabelTarget.innerHTML = '"' + currentInput.value + '"'
    } else {
      if (this.hasCreateButtonTarget) this.createButtonTarget.classList.add('hidden')
    }

    lis.forEach(
      (li) => li.dataset.label.toLowerCase().includes(value)
        ? li.classList.remove('hidden')
        : li.classList.add('hidden')
    )
  }

  selectMultiItem (event) {
    this.dispatchEvent(event)

    const checkbox = event.currentTarget.querySelector('button')
    checkbox.toggleAttribute('data-checked')
  }

  dispatchEvent (event) {
    this.element.dispatchEvent(new CustomEvent('select.omnis',
      {
        detail: {
          value: event.currentTarget.getAttribute('data-value'),
          label: event.currentTarget.getAttribute('data-label') +
             (event.currentTarget.getAttribute('data-right-label')
               ? ` - ${event.currentTarget.getAttribute('data-right-label')}`
               : ''
             )
        }
      }))
  }
}
