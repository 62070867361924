import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { post } from '@rails/request.js'

// This controller will have the browser asyncronously post data to an end point and then redirect.

// Connects to data-controller="post-callback"
export default class extends Controller {
  static values = { redirectUrl: String, callbackUrl: String, jsonData: String }
  connect () {
    setTimeout(() => {
      this.element.remove()
      this.#post_and_redirect()
    }, 10)
  }

  async #post_and_redirect () {
    await post(this.callbackUrlValue, { body: this.jsonDataValue })
    Turbo.visit(this.redirectUrlValue, { action: 'replace' })
  }
}
