import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="refresh-page"
export default class extends Controller {
  static values = { delay: { type: Number, default: 8000 } }

  connect () {
    this.timeoutID = setTimeout(() => {
      if (!this.element.checkVisibility()) return

      Turbo.visit(window.location, { action: 'replace' })
    }, this.delayValue)
  }

  disconnect () {
    clearTimeout(this.timeoutID)
  }
}
