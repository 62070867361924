import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="link-follower"
export default class extends Controller {
  trigger (event) {
    Turbo.visit(this.element.value)
  }

  triggerWithHrefParam (event) {
    Turbo.visit(event.params.href)
  }
}
