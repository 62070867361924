import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pos-product-buttons-grid"
export default class extends Controller {
  change (event) {
    event.currentTarget.querySelector("[name*='[row]']").value = event.detail.newRow
    event.currentTarget.querySelector("[name*='[column]']").value = event.detail.newCol

    // we submit the form only for the target change
    if (event.detail.type === 'target') this.element.querySelector('form').requestSubmit()
  }

  updateRowCol (event) {
    const rowHiddenInput = event.currentTarget.querySelector("[name*='[row]']")
    const columnHiddenInput = event.currentTarget.querySelector("[name*='[column]']")
    const link = event.currentTarget.querySelector("a[href*='[row]']")

    if (rowHiddenInput) rowHiddenInput.value = event.detail.newRow
    if (columnHiddenInput) columnHiddenInput.value = event.detail.newCol

    if (link) {
      const url = new URL(link.href)
      const linkParams = new URLSearchParams(url.search)
      linkParams.set('row', event.detail.newRow)
      linkParams.set('column', event.detail.newCol)
      url.search = linkParams.toString()
      link.href = url.toString()
    }

    // we submit the form only for the target change
    if (event.detail.type === 'target') this.element.querySelector('[name="assign-only"]').click()
  }
}
