import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'icon', 'hiddenFields']
  static values = { multiSelect: Boolean }

  connect () {
    this.toggleIconsOnLoad()
  }

  toggleIconsOnLoad () {
    this.inputTargets.forEach((input) => {
      if (input.checked) {
        this.toggleCheckboxIcon(input)
      }
    })
  }

  toggleIcon (event) {
    const clickedInput = event.currentTarget

    if (this.multiSelectValue) {
      this.toggleCheckboxIcon(clickedInput)
    } else {
      this.uncheckOthers(clickedInput)
    }
  }

  toggleCheckboxIcon (input) {
    const icon = input.parentElement.querySelector('span').parentElement
    icon.classList.toggle('hidden', !input.checked)
  }

  uncheckOthers (selectedInput) {
    this.inputTargets.forEach((input) => {
      if (input !== selectedInput) {
        input.checked = false
        const icon = input.parentElement.querySelector('span').parentElement
        icon.classList.add('hidden')
      } else {
        this.toggleCheckboxIcon(input)
      }
    })
  }
}
