import { Controller } from '@hotwired/stimulus'
import { computePosition, autoUpdate, flip, shift, limitShift } from '@floating-ui/dom'

// Connects to data-controller="select"
export default class extends Controller {
  static targets = ['dropdown', 'value', 'label', 'labelTemplate',
    'tertiaryButton', 'tertiaryLabelContainer', 'icon']

  static values = { type: String, isCumulative: Boolean, withTertiaryCheckbox: Boolean }

  connect () {
    this.dropdownElement = null
    this.isMulti = this.typeValue === 'multi-select'
    this.isMultiTertiary = this.typeValue === 'multi-select-tertiary'

    // Setup button label text if the browser decides to cache the hidden input value so that
    // it no longer matches the generated DOM content.
    const selectedElem = this.dropdownTarget.content
      .querySelector('li[data-value="' + this.valueTarget.value + '"]')
    if (selectedElem) {
      this.element.removeAttribute('data-empty')
      this.labelTarget.innerHTML = selectedElem.getAttribute('data-label')
    }
  }

  disconnect () {
    if (this.dropdownElement) {
      this.dropdownElement.remove()
      if (this.cleanupAutoUpdate) {
        this.cleanupAutoUpdate()
      }
      this.dropdownElement = null
    }
  }

  toggleDropdown (event) {
    event.preventDefault()

    if (this.dropdownElement) {
      this.hide(event)
    } else {
      this.show(event)
    }
  }

  show (event) {
    this.dropdownElement = this.dropdownTarget.content.firstElementChild.cloneNode(true)
    this.dropdownElement.style.width = this.element.getBoundingClientRect().width + 'px'

    // if we can have only one item of each, we remove from the dropdown the items already selected
    if (this.isMultiTertiary && !(this.isCumulativeValue || this.withTertiaryCheckboxValue)) {
      this.hideAlreadySelectedItems()
    }

    // we mark the selection accordingly to the hidden input
    if (this.isMulti || this.withTertiaryCheckboxValue) {
      this.setMultiSelections()
    } else if (!this.isMultiTertiary) { // otherwise we are in a single selection case
      this.setSelection()
    }

    const dropdownContainer = event && event.params.inline ? this.element : document.getElementById('dropdown-container')
    dropdownContainer.appendChild(this.dropdownElement)
    this.placeDropdown()
    this.iconUp()

    this.dropdownElement.addEventListener('closed.omnis', (e) => {
      this.dropdownElement = null
      this.iconDown()
    })

    this.dropdownElement.addEventListener('select.omnis', (e) => {
      if (this.isMulti) {
        this.setMultiValuesAndLabels(e)
      } else if (this.isMultiTertiary) {
        this.setMultiTertiaryValuesAndLabels(e)
      } else {
        this.setValueAndLabel(e)
      }

      this.element.dispatchEvent(new Event('changed.omnis'))
    })

    setTimeout(() => {
      const scrollingListElement = this.dropdownElement.querySelector('ul')
      const selectedElem = scrollingListElement.querySelector('li[data-selected]')
      if (selectedElem) {
        const menuRect = scrollingListElement.getBoundingClientRect()
        const elemRect = selectedElem.getBoundingClientRect()
        const relYPos = elemRect.y - menuRect.y // Element Y position relative to menu box
        const newScroll = relYPos - (menuRect.height - elemRect.height) / 2
        scrollingListElement.scrollTo(0, newScroll)
      }
    }, 0)
  }

  hide (event) {
    this.dropdownElement.remove()
    this.iconDown()
    if (this.cleanupAutoUpdate) {
      this.cleanupAutoUpdate()
    }
    this.dropdownElement = null
  }

  tertiaryRemove (e) {
    // we remove the associated hidden input
    const value = e.target.closest('[data-value]').dataset.value
    const input = this.valueTarget.querySelector(`input[value="${value}"]`)
    input.remove()
    this.valueTarget.dispatchEvent(new Event('change'))

    this.updateTertiaryLabelContainer()
  }

  setSelection () {
    const selectedElem = this.dropdownElement.querySelector('li[data-value="' + this.valueTarget.value + '"')
    if (selectedElem) {
      selectedElem.setAttribute('data-selected', '')
    }
  }

  setMultiSelections () {
    const lis = this.dropdownElement.querySelectorAll('li')

    const values = Array.from(this.valueTarget.querySelectorAll('input')).map((input) => input.value)

    for (const li of lis) {
      const checkbox = li.querySelector('button')
      if (values.includes(li.dataset.value)) {
        checkbox.dataset.checked = true
      } else {
        delete checkbox.dataset.checked
      }
    };
  }

  setValueAndLabel (e) {
    if (this.valueTarget.value !== e.detail.value) {
      this.valueTarget.value = e.detail.value
      this.valueTarget.dispatchEvent(new Event('change'))
    }
    this.labelTarget.innerHTML = e.detail.label
    this.element.removeAttribute('data-empty')
  }

  setMultiValuesAndLabels (e) {
    const existingElement = this.valueTarget.querySelector(`input[value='${e.detail.value}']`)

    if (existingElement) {
      // we remove the existing hidden input
      existingElement.remove()

      // we remove the existing label
      Array.from(this.labelTarget.querySelectorAll('span'))
        .find(el => el.innerText.includes(e.detail.label))
        .remove()
    } else {
      // we add the new hidden input
      this.addHiddenInput(e.detail.value)

      // we remove the placeholder (if any)
      this.labelTarget.querySelector('[data-placeholder]')?.remove()

      // we add the new label
      const templateClone = document.importNode(this.labelTemplateTarget.content, true)
      const newLabel = templateClone.querySelector('span')
      newLabel.innerText = e.detail.label
      this.labelTarget.append(newLabel)
    }
  }

  setMultiTertiaryValuesAndLabels (e) {
    if (this.withTertiaryCheckboxValue) {
      const existingElement = this.valueTarget.querySelector(`input[value='${e.detail.value}']`)

      if (existingElement) {
        existingElement.remove()
      } else {
        this.addHiddenInput(e.detail.value)
      }
    } else {
      // we add the new hidden input
      this.addHiddenInput(e.detail.value)
    }
    // we update the labels
    this.updateTertiaryLabelContainer()
  }

  placeDropdown () {
    const element = this.isMultiTertiary ? this.tertiaryButtonTarget : this.element

    const cleanup = autoUpdate(element, this.dropdownElement, () => {
      if (element instanceof Element && this.dropdownElement instanceof Element) {
        computePosition(element, this.dropdownElement, {
          placement: 'bottom-start',
          middleware: [flip(), shift({ limiter: limitShift() })]
        }).then(({ x, y }) => {
          Object.assign(this.dropdownElement.style, {
            position: 'absolute',
            left: `${x}px`,
            top: `${y}px`
          })
        })
      }
    })

    // Store the cleanup function in an instance variable so you can call it later
    this.cleanupAutoUpdate = cleanup
  }

  addHiddenInput (value) {
    const newHiddenInput = document.createElement('input')
    newHiddenInput.type = 'hidden'
    newHiddenInput.name = this.valueTarget.dataset.templateName
    newHiddenInput.value = value
    this.valueTarget.append(newHiddenInput)
    this.valueTarget.dispatchEvent(new Event('change'))
  }

  updateTertiaryLabelContainer () {
    // we remove the existing labels
    this.tertiaryLabelContainerTarget.innerHTML = ''

    // we add the new ones based on the hidden inputs
    const templateClone = document.importNode(this.labelTemplateTarget.content, true)
    const labels = templateClone.querySelectorAll('div')
    for (const newLabel of labels) {
      // we count the number of hidden inputs by value
      const count = this.valueTarget.querySelectorAll(`input[value="${newLabel.dataset.value}"]`).length

      // we don't add the label if the count is not positive
      if (count <= 0) continue

      newLabel.innerHTML = newLabel.innerHTML.replace('to-replace', count)
      this.tertiaryLabelContainerTarget.append(newLabel)
    }
  }

  hideAlreadySelectedItems () {
    this.valueTarget.querySelectorAll('input[value]:not([value=""]').forEach(
      (input) => {
        this.dropdownElement.querySelector(`li[data-value="${input.value}"]`).style.display = 'none'
      }
    )
  }

  toggleIcon (isUp) {
    const iconElement = this.hasIconTarget && (this.iconTarget.querySelector('i') || this.iconTarget.querySelector('span'))

    if (iconElement) {
      iconElement.classList.toggle('i-angle-up', isUp)
      iconElement.classList.toggle('i-angle-down', !isUp)
    }
  }

  iconDown () {
    this.toggleIcon(false)
  }

  iconUp () {
    this.toggleIcon(true)
  }
}
