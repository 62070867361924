import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pos-product-button"
export default class extends Controller {
  static targets = ['destroyField']

  remove (event) {
    event.preventDefault()

    this.destroyFieldTarget.removeAttribute('disabled')

    this.element.closest('form').querySelector('[name="assign-only"]').click()
  }
}
