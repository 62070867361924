import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="field-memento"
export default class extends Controller {
  static values = {
    storageKey: String // The storage key holding the persisted state
  }

  static targets = ['input']

  connect () {
    const initialValue = localStorage.getItem(this.storageKeyValue)
    if (initialValue) {
      this.#get_field().value = initialValue
    }
  }

  update () {
    localStorage.setItem(this.storageKeyValue, this.#get_field().value)
  }

  #get_field () {
    // Lookup field value through the form to support radio buttons as well.
    return this.element.elements.namedItem(this.inputTarget.name)
  }
}
