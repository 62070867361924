import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="scale-content-to-fit"
export default class extends Controller {
  connect () {
    const content = this.element.firstElementChild

    this.element.style.position = 'relative'

    content.style.position = 'absolute'
    content.style.transformOrigin = 'top left'
    content.style.transform = `scale(${this.element.offsetWidth / content.offsetWidth},` +
                                    `${this.element.offsetHeight / content.offsetHeight})`
    content.style.visibility = 'visible'
  }
}
